
import * as Components from "./Hamburguetza"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "hamburguetza"
}

